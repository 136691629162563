/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

interface DefaultOutboundLinkProps {
  to: string
}

const DefaultOutboundLink: React.FC<DefaultOutboundLinkProps> = ({
  to,
  children
}) => (
  <OutboundLink
    href={to}
    target="_blank"
    rel="noreferrer noopener"
    css={css`
      font-family: Charter, Georgia, 'Times New Roman', Times, serif;
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      @media only screen and (max-width: 640px) {
        font-size: 16px;
      }
    `}
  >
    {children}
  </OutboundLink>
)

export default DefaultOutboundLink
