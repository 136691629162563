import React from 'react'
import Helmet from 'react-helmet'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import MediaQuery from 'react-responsive'
import styled from '@emotion/styled'

import Layout from '../../layouts'
import StarRating from '../../components/StarRating'
import HeaderText from '../../components/HeaderText'
import HeaderWrapper from '../../components/HeaderWrapper'
import DefaultOutboundLink from '../../components/DefaultOutboundLink'

const BodyWrapper = styled.div`
  max-width: 700px;
  margin: 60px auto;
`

const Row = styled.div`
  display: flex;
  height: 280px;
  @media only screen and (max-width: 640px) {
    height: 230px;
  }
`

const ImgCol = styled.div`
  width: 200px;
  @media only screen and (max-width: 640px) {
    width: 110px;
  }
`

const TextCol = styled.div`
  width: 100%;
  padding: 50px 30px;
  @media only screen and (max-width: 640px) {
    padding: 20px 20px;
  }
`

const BookTitle = styled.h4`
  font-size: 22px;
  margin: 0 0 8px 0;
  @media only screen and (max-width: 640px) {
    font-size: 18px;
  }
`

const BookAuthor = styled.p`
  margin: 0;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
`

/* We don't use emotion here because we need to use the style tag for gatsby-images. */
const imgStyle = {
  display: 'block',
  margin: '20px auto',
  borderRadius: '3px',
  boxShadow:
    '0 30px 60px -10px rgba(0,0,0,0.3), 0 18px 36px -18px rgba(0,0,0,0.33)'
}

interface BookEntry {
  node: {
    title: string
    author: string
    rating: number
    link: string
    image: ImageDataLike
  }
}

interface BooksPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    allBooksYaml: {
      edges: BookEntry[]
    }
    mobileImages: {
      edges: BookEntry[]
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allBooksYaml {
      edges {
        node {
          title
          author
          rating
          link
          image {
            relativePath
            childImageSharp {
              gatsbyImageData(
                height: 220
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    mobileImages: allBooksYaml {
      edges {
        node {
          image {
            relativePath
            childImageSharp {
              gatsbyImageData(
                height: 150
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`

const BooksPage: React.FC<BooksPageProps> = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const books = data.allBooksYaml.edges.map(({ node }) => node)
  const mobileBooks = data.mobileImages.edges.map(({ node }) => node)

  const subheading =
    "Here's a list of books I've recently read. Books that I've written blog posts for have links attached to them."

  return (
    <Layout>
      <Helmet>
        <title>{`Books | ${siteTitle}`}</title>
        <meta name="description" content={subheading} />

        {/* Metadata for Open Graph */}
        <meta property="og:title" content={`Books | ${siteTitle}`} />
        <meta property="og:description" content={subheading} />
      </Helmet>
      <HeaderWrapper>
        <HeaderText>{'Recently read books'}</HeaderText>
        <h2>{subheading}</h2>
      </HeaderWrapper>
      <BodyWrapper>
        {books.map((book, index) => {
          const desktopImg = getImage(book.image)
          const mobileImg = getImage(mobileBooks[index].image)

          if (!desktopImg || !mobileImg) {
            throw Error(`Image for ${book.title} missing.`)
          }
          return (
            <Row key={index}>
              <ImgCol>
                <MediaQuery minWidth={640}>
                  <GatsbyImage
                    alt={book.title}
                    image={desktopImg}
                    style={imgStyle}
                  />
                </MediaQuery>
                <MediaQuery maxWidth={640}>
                  <GatsbyImage
                    alt={book.title}
                    image={mobileImg}
                    style={imgStyle}
                  />
                </MediaQuery>
              </ImgCol>
              <TextCol>
                <BookTitle>{book.title}</BookTitle>
                <BookAuthor>{book.author}</BookAuthor>
                <StarRating rating={book.rating} />
                <DefaultOutboundLink to={book.link}>
                  {'Find it on Amazon'}
                </DefaultOutboundLink>
              </TextCol>
            </Row>
          )
        })}
      </BodyWrapper>
    </Layout>
  )
}

export default BooksPage
