import React from 'react'
import styled from '@emotion/styled'

const filledStar = '\u2605'
const emptyStar = '\u2606'

const StarRatingWrapper = styled.p`
  font-size: 18px;
  margin: 4px 0;
  font-weight: 300;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
    margin: 2px 0;
  }
`

export interface StarRatingProps {
  rating: number
}

/**
 * Displays a rating out of five stars.
 *
 * @param {number} rating - Must be an integer between 0 and 5.
 */
const StarRating: React.FC<StarRatingProps> = ({ rating }: StarRatingProps) => {
  if (!(rating >= 0 && rating <= 5)) {
    throw Error('Rating must be between 0 and 5.')
  }
  const stars = [
    ...Array(rating).fill(filledStar),
    ...Array(5 - rating).fill(emptyStar)
  ].join('')

  return <StarRatingWrapper>{stars}</StarRatingWrapper>
}

export default StarRating
